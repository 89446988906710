import { get, isUndefined } from 'lodash';
import { call } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FormattedError } from '../../domain/entities/error';

export function* handleError(e: Error) {
  const prettyError: unknown = get(e, 'response.data.data[0]');

  if (isFormattedError(prettyError)) {
    yield call(toastr.error, prettyError.title, prettyError.detail);
  } else {
    yield call(toastr.error, 'Unknown Error', 'Please try again');
    console.log(e);
  }
}

const isFormattedError = (data: unknown): data is FormattedError => {
  const code = get(data, 'response_code');

  return !isUndefined(code);
};
