import React, { FC, useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';

import { useAppDispatch } from '../../interface-adapters//store/hooks';
import { changeNotActivePhone } from '../../interface-adapters/user/userSaga';
import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../../interface-adapters/global/ButtonWithLoading';
import { CrossButton } from '../CrossButton';
import { PhoneInput } from '../../components/PhoneInput';

const SEND_CODE_DELAY = 30;

interface Props {
  onCrossClick: () => void;
  onPhoneNumberSend: () => void;
  defaultPhone: string;
}

export const PhoneNumberPage: FC<Props> = ({
  onCrossClick,
  onPhoneNumberSend,
  defaultPhone,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhone);
  const dispatch = useAppDispatch();
  const defaultCountdownDelay = isEmpty(defaultPhone) ? 0 : SEND_CODE_DELAY;
  const [delay, setDelay] = useState(defaultCountdownDelay);

  const countdown = useCallback(() => {
    setDelay(delay - 1);
  }, [delay]);

  useEffect(() => {
    if (delay > 0) {
      window.setTimeout(countdown, 1000);
    }
  }, [delay, countdown]);

  const canUpdate = !isEmpty(phoneNumber) && delay === 0;
  const sendCode = () => {
    setDelay(SEND_CODE_DELAY);
    dispatch(changeNotActivePhone({phoneNumber}));
    onPhoneNumberSend();
  };

  const countdownTitle = delay > 0 ? delay : '';
  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendCode} isDisabled={!canUpdate}>
            {`Update ${countdownTitle}`}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={onCrossClick} />
      <div className="l-row">
        <div className="c-title">Update your mobile phone number</div>
      </div>
      <div className="l-row">
        <PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
      </div>
    </Layout>
  );
};
