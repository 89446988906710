import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { useAppDispatch } from '../store/hooks';
import { setProfile } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';

export const ProfilePage: FC = () => {
  const dispatch = useAppDispatch();
  useRedirectOnAuthStepChangeEffct();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const canSetProfile = !isEmpty(firstName) && !isEmpty(lastName);
  const next = () => {
    dispatch(
      setProfile({
        firstName,
        lastName,
      }),
    );
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={next} isDisabled={!canSetProfile}>
            Continue
          </ButtonWithLoading>
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">Please enter your name</div>
      </div>
      <div className="l-row">
        <div className="c-input _c-input--validation">
          <div className="c-input__message c-input__message--error">Error message...</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <span className="c-input__title">First Name</span>
          </label>
        </div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-input _c-input--validation">
          <div className="c-input__message c-input__message--error">Error message...</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <span className="c-input__title">Last Name</span>
          </label>
        </div>
      </div>
      <div className="l-row">Card Preview</div>
      <div className="l-row l-row--mt-16">
        <div className="c-card">
          <div className="c-card__name">
            {firstName} {lastName}
          </div>
          <div className="c-card__number">1234 - 1234 - 1234</div>
        </div>
      </div>
    </Layout>
  );
};
