import React, { FC, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { CrossButton } from '../../components/CrossButton';
import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { getProfile } from '../dbStore/dbStore';
import { changeProfile } from './userSaga';
import { useDispatchWithHistory } from '../actionWithHistory';

export const ChangeProfilePage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const history = useHistory();
  const profile = useSelector(getProfile);
  const [firstName, setFirstName] = useState(!isUndefined(profile) ? profile.firstName : '');
  const [lastName, setLastName] = useState(!isUndefined(profile) ? profile.lastName : '');

  const canUpdate = !isEmpty(firstName) && !isEmpty(lastName);
  const updateName = () => {
    dispatch(changeProfile, {
      firstName,
      lastName,
    });
  };

  const handleBack = () => {
    history.push(routes.profile);
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={updateName} isDisabled={!canUpdate}>
            Update
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />
      <div className="l-row">
        <div className="c-title">Update Personal Info</div>
      </div>
      <div className="l-row">
        <div className={`c-input`}>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <span className="c-input__title">First Name</span>
          </label>
        </div>
      </div>
      <div className="l-row">
        <div className={`c-input`}>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <span className="c-input__title">Last Name</span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
