import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { getCard, getProfile } from '../dbStore/dbStore';
import { getUserCard } from './userSaga';
import { useAppDispatch } from '../store/hooks';
import { getIsAuthenticated } from '../auth/authStore';
import { restoreCard } from '../card/cardStore';
import { InviteFriendButton } from './InviteFriendButton';

export const ProfilePage: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const profile = useSelector(getProfile);
  const isAuth = useSelector(getIsAuthenticated);
  const card = useSelector(getCard);

  let firstName = '',
    lastName = '';
  if (!isUndefined(profile)) {
    firstName = profile.firstName;
    lastName = profile.lastName;
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserCard());
    }
  }, [isAuth, dispatch]);

  const handleRestoreCard = useCallback(() => {
    dispatch(restoreCard());
  }, [dispatch]);

  const handleOnUpdateProfileCLick = useCallback(() => {
    history.push(routes.profileChangeName);
  }, [history]);

  const handleOnUpdatePhoneCLick = useCallback(() => {
    history.push(routes.profileChangePhone);
  }, [history]);

  const handleOnUpdateEmailCLick = useCallback(() => {
    history.push(routes.profileChangeEmail);
  }, [history]);

  return (
    <Layout>
      <div className="l-row">
        <div className="c-title">Your Account</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-title c-title--fs-14">Hello, {firstName}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          This is your Hey!Joy loyalty program account where you can check your balance, invite
          friends and manage your personal information.
        </div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-line" />
      </div>
      <div className="l-row l-row--mt-16">
        Balance:
        <b>5634</b>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-card">
          <div className="c-card__name">
            {firstName} {lastName}
          </div>
          <div className="c-card__number">{card?.cardNumber}</div>
        </div>
      </div>
      {/* <div className="l-row l-row--mt-16">
        <div className="c-copy">
          <input className="c-copy__type" type="text" value="123456789" readOnly />
          <button className="c-copy__button c-button" type="button">
            <span className="c-copy__button--inner">
              Copy Code
              Copied!
            </span>
          </button>
        </div>
      </div> */}
      <div className="l-row l-row--mt-16">
        <div className="c-get">
          <div className="l-row">
            <b>Hey!Joy referral program</b>
          </div>
          <div className="l-row l-row--mt-16">
            <b>Invite your friend and earn 5 bonuses</b>
            <div className="c-text">
              When new client install Hey!Joy loyalty card and create new account both you and your friend will earn 5 bonuses.
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <InviteFriendButton />
          </div>
        </div>
      </div>
      <div className="l-row">
        <button className="c-link c-link--blue" onClick={handleRestoreCard}>
          Download Card
        </button>
      </div>
      <div className="l-row">
        <button className="c-link c-link--blue" onClick={handleOnUpdateProfileCLick}>
          Update Name on Card
        </button>
      </div>
      <div className="l-row l-row--mt-16">
        <button className="c-link c-link--blue" onClick={handleOnUpdatePhoneCLick}>
          Update Phone Number
        </button>
      </div>
      <div className="l-row l-row--mt-16">
        <button className="c-link c-link--blue" onClick={handleOnUpdateEmailCLick}>
          Update Email
        </button>
      </div>
    </Layout>
  );
};
