import { SignupStep } from '../../domain/entities/signup';
import { routes } from '../../domain/routes';

export const signupPageByState: Record<SignupStep, string> = {
  [SignupStep.Basic]: routes.signupTerms,
  [SignupStep.Profile]: routes.signupProfile,
  [SignupStep.SetPhone]: routes.signupSetPhone,
  [SignupStep.PhoneActivation]: routes.signupPhoneActivation,
  [SignupStep.PhoneActivationSuccess]: routes.signupPhoneActivation,
  [SignupStep.SetEmail]: routes.signupPhoneActivationSuccess,
  [SignupStep.Completed]: routes.signupCheckInbox,
};
