import { takeLatest, call, select, put } from 'redux-saga/effects';
import {
  acceptTermsByUser,
  activatePhone,
  resendActivationCode,
  setPhone,
  setProfile,
  setEmail,
} from './signupStore';
import {
  acceptTermsAPI,
  activatePhoneAPI,
  resendActivationCodeAPI,
  setPhoneAPI,
  setProfileAPI,
  setEmailAPI,
} from '../api/signup';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ActivatePhoneActionPayload,
  SetPhoneActionPayload,
  SetProfileActionPayload,
  StartActivationTermsActionPayload,
  SetEmailActionPayload,
} from '../../boundries/actionsPayloads/signupActionPayloads';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { StartActivationTermsAttributesDTO } from '../../boundries/responseDTO/signup';
import { getUserByIdFn } from '../dbStore/dbStore';
import { UserEntity } from '../../domain/entities/user';
import { ActionWithHistory } from '../actionWithHistory';
import { routes } from '../../domain/routes';
import { setToken } from '../auth/authStore';
import { ClientResponse } from '../../boundries/client';
import { handleError } from '../error/utils';
import { finishRequest, startRequest } from '../global/globalStore';

function* acceptTermsByUserSaga(action: PayloadAction<StartActivationTermsActionPayload>) {
  try {
    const userId = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<StartActivationTermsAttributesDTO>> =
      yield call(acceptTermsAPI, userId);

    yield call<saveJsonApiResponseToDbFunction<StartActivationTermsAttributesDTO>>(
      saveJsonApiResponseToDb,
      response.data,
    );

    const getUserById: ReturnType<typeof getUserByIdFn> = yield select(getUserByIdFn);
    const user: UserEntity | null = yield call(getUserById, userId);

    if (!user) throw Error(`user not found, id: ${userId}`);

    yield put(setToken(user.token));
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setProfileSaga(action: PayloadAction<SetProfileActionPayload>) {
  try {
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      setProfileAPI,
      action.payload,
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setPhoneSaga(action: PayloadAction<SetPhoneActionPayload>) {
  try {
    const phoneNumber = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      setPhoneAPI,
      phoneNumber,
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* activatePhoneSaga(action: PayloadAction<ActivatePhoneActionPayload>) {
  try {
    const phoneNumber = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      activatePhoneAPI,
      parseInt(phoneNumber, 10),
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* resendActivationCodeSaga() {
  try {
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      resendActivationCodeAPI,
    );
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setEmailSaga(action: PayloadAction<ActionWithHistory<SetEmailActionPayload>>) {
  try {
    const {
      data: { email },
      history,
    } = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(setEmailAPI, email);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    yield call(history.push, routes.signupCheckInbox);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

export function* signupSaga() {
  yield takeLatest(acceptTermsByUser, acceptTermsByUserSaga);
  yield takeLatest(setProfile, setProfileSaga);
  yield takeLatest(setPhone, setPhoneSaga);
  yield takeLatest(activatePhone, activatePhoneSaga);
  yield takeLatest(resendActivationCode, resendActivationCodeSaga);
  yield takeLatest(setEmail, setEmailSaga);
}
