import { ReactNode } from 'react';
import { Layout } from './Layout';
import { Button } from './Button';

interface Props {
  title?: string;
  buttonTitle: string;
  onClick: () => void;
  children: ReactNode;
}

export const SuccessPage = ({ buttonTitle, onClick, children, title='Success!' }: Props): JSX.Element => (
  <Layout
    footer={
      <footer className="l-footer">
        <Button title={buttonTitle} onClick={onClick} />
      </footer>
    }
  >
    <div className="l-row">
      <div className="c-title">{title}</div>
    </div>
    <div className="l-row">
      <svg className="icon icon--check">
        <use xlinkHref="img/_icons.svg#icon--check" />
      </svg>
      {/* <svg className="icon icon--error">
        <use xlinkHref="img/_icons.svg#icon--error" />
      </svg> */}
    </div>
    {children}
  </Layout>
);
