export const routes = {
  home: '/',
  dev: '/dev',
  terms: '/terms',
  createCard: '/create-card',
  validatePhone: '/create-card-validate',
  downloadCard: '/download-card',
  downloadCardByReferral: '/download-card-ref/:referralId',
  restoreCard: '/create-card-restore',

  signupTerms: '/signupTerms/:userId',
  signupProfile: '/signup/profile',
  signupSetPhone: '/signup/set-phone',
  signupPhoneActivation: '/signup/phone-activation',
  signupPhoneActivationSuccess: '/signup/phone-activation-success',
  signupSetEmail: '/signup/set-email',
  signupCheckInbox: '/signup/check-inbox',
  signupVerifyEmailSuccess: '/signup/verify-email-success',
  signupVerifyEmailError: '/signup/verify-email-error',

  signIn: '/sign-in',
  signInvalidatePhoneOtp: '/sign-in/validate-phone-otp/:phoneNumber',

  profile: '/profile',
  profileChangePhone: '/profile/change-phone',
  profileChangePhoneActivation: '/profile/change-phone-activation/:phoneNumber',
  profileChangeEmail: '/profile/change-email',
  profileChangeName: '/profile/change-name',
  profileInviteFriend: '/profile/invite-friend',
};
