import React, { FC, useState, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { setEmail } from './signupStore';
import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { isEmailValid } from '../user/utils';
import { useDispatchWithHistory } from '../actionWithHistory';

export const EmailVerificationPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const [mail, setMail] = useState('');
  const [mailError, setMailError] = useState('');
  const [confirmMail, setConfirmMail] = useState('');
  const [confirmMailError, setConfirmMailError] = useState('');

  useEffect(() => {
    if (!isEmpty(mail) && !isEmailValid(mail)) {
      setMailError('Email is not valid');
    } else if (isEmailValid(confirmMail) && !isEqual(mail, confirmMail)) {
      setMailError('Emails are not equal');
    } else {
      setMailError('');
    }
  }, [mail, confirmMail]);

  useEffect(() => {
    if (!isEmpty(confirmMail) && !isEmailValid(confirmMail)) {
      setConfirmMailError('Email is not valid');
    } else if (isEmailValid(mail) && !isEqual(mail, confirmMail)) {
      setConfirmMailError('Emails are not equal');
    } else {
      setConfirmMailError('');
    }
  }, [confirmMail, mail]);

  const canVerifyEmail = !isEmpty(mail) && !isEmpty(confirmMail) && isEqual(mail, confirmMail);
  const sendConfirmation = () => {
    dispatch(setEmail, {
      email: mail,
    });
  };

  const mailValidationClass = !isEmpty(mailError) ? 'c-input--validation' : '';
  const confirmMailValidationClass = !isEmpty(confirmMailError) ? 'c-input--validation' : '';

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendConfirmation} isDisabled={!canVerifyEmail}>
            Send Confirmation
          </ButtonWithLoading>
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">Verify Email Address!</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          Please enter your valid email address. 
        </div>
      </div>
      <div className="l-row">
        <div className={`c-input ${mailValidationClass}`}>
          <div className="c-input__message c-input__message--error">{mailError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={mail}
              onChange={(event) => setMail(event.target.value)}
            />
            <span className="c-input__title">Email</span>
          </label>
        </div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className={`c-input ${confirmMailValidationClass}`}>
          <div className="c-input__message c-input__message--error">{confirmMailError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={confirmMail}
              onChange={(event) => setConfirmMail(event.target.value)}
            />
            <span className="c-input__title">Confirm Email</span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
