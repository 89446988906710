import { updateCtx } from './interface-adapters/diContext';
import { createInstance, updateClientJwt } from './interface-adapters/client';
import { store } from './interface-adapters/store/store';
import { getToken } from './interface-adapters/auth/authStore';

export const initDi = () => {
  // store needs to be initialized first since client have dependency on it
  updateCtx({ store });
  const jwt = getToken(store.getState());

  updateCtx({
    client: {
      privateClient: createInstance(jwt || ''),
      publicClient: createInstance(),
      updateClientJwt,
    },
  });
};
