import React, { FC, useCallback } from 'react';

import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Button } from '../../components/Button';

export const CheckInboxPage: FC = () => {
  const history = useHistory();

  const handleCheckBalance = useCallback(() => {
    history.push(routes.profile);
  }, [history]);

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <Button onClick={handleCheckBalance}>Your account</Button>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">Check your inbox!</div>
      </div>
      <div className="l-row">
        <div className="c-text">Confirm your email!</div>
      </div>
      <div className="l-row">
        <svg className="icon icon--envelope">
          <use xlinkHref="img/_icons.svg#icon--envelope" />
        </svg>
      </div>
    </Layout>
  );
};
