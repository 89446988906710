import {
  PhoneActivateRequestDTO,
  SetPhoneTermsRequestDTO,
  SetProfileRequestDTO,
  StartActivationTermsRequestDTO,
  SetEmailRequestDTO,
} from '../../boundries/requestDTO/signup';
import { getCtx } from '../diContext';

export const acceptTermsAPI = (userId: string) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: StartActivationTermsRequestDTO = {
    data: {
      attributes: {
        agreement: true,
      },
    },
  };
  return publicClient.post(`/user/${userId}/activation/basic/start/terms`, payload);
};

export const setProfileAPI = (data: { firstName: string; lastName: string }) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetProfileRequestDTO = {
    data: {
      attributes: data,
    },
  };
  return privateClient.post(`/user/activation/basic/profile`, payload);
};

export const setPhoneAPI = (phoneNumber: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetPhoneTermsRequestDTO = {
    data: {
      attributes: {
        phone: phoneNumber,
      },
    },
  };
  return privateClient.post(`/user/activation/basic/setPhone`, payload);
};

export const activatePhoneAPI = (code: number) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: PhoneActivateRequestDTO = {
    data: {
      attributes: {
        code,
      },
    },
  };
  return privateClient.post(`/user/activation/basic/phoneActivation`, payload);
};

export const resendActivationCodeAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.post(`/user/phone/activation/resend`);
};

export const setEmailAPI = (email: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetEmailRequestDTO = {
    data: {
      attributes: {
        email: email,
      },
    },
  };
  return privateClient.post(`/user/activation/basic/setEmail`, payload);
};
