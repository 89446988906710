import { FC, useCallback } from 'react';

import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { SuccessPage } from '../../components/SuccessPage';

export const PhoneActivationSuccessPage: FC = () => {
  const history = useHistory();

  const handleVerifyEmail = useCallback(() => {
    history.push(routes.signupSetEmail);
  }, [history]);

  const handleCheckBalance = useCallback(() => {
    history.push(routes.profile);
  }, [history]);

  return (
    <SuccessPage title="You’re in!" buttonTitle="Check Balance" onClick={handleCheckBalance}>
      <div className="l-row">
        <div className="c-text">
          Welcome to Hey!Joy loyalty program and all its benefits.
          <br />
          Enjoy a wide range of earnings, offers and more.
        </div>
      </div>
      <div className="l-row">
        <b>YOU WILL RECEIVE YOUR BONUSES SHORTLY!</b>
      </div>
      <div className="l-row">
        <div className="c-line" />
      </div>
      <div className="l-row">
        <b>Earn more bonuses</b>
      </div>
      <div className="l-row l-row--mt-16">
        <b>Bonuses Program</b>
        <div className="c-text">Add your email to earn more bonuses.</div>
      </div>
      <div className="l-row">
        <button className="c-link" onClick={handleVerifyEmail}>
          Verify Your Email Link
        </button>
      </div>
    </SuccessPage>
  );
};
