import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from '../interface-adapters/navigation/router';
import { routes } from '../domain/routes';

interface Props {
  footer?: ReactNode;
}

const useDevModeEffct = () => {
  const [clicks, setClicks] = useState(0);
  const history = useHistory();
  const handler = () => setClicks(clicks + 1);

  useEffect(() => {
    if (clicks > 10) history.push(routes.dev);
  }, [clicks, history]);

  return handler;
};
export const Layout: FC<Props> = ({ children, footer }) => {
  const onClick = useDevModeEffct();

  return (
    <div className="l-page" onClick={onClick}>
      <main className="l-main">{children}</main>
      {footer}
    </div>
  );
};
