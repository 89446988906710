import React, { FC } from 'react';
import PhoneInputComponent, { PhoneInputProps } from 'react-phone-number-input';
import './phone-styles.css';

export const PhoneInput: FC<PhoneInputProps> = (props) => (
  <div className="c-phone">
    <PhoneInputComponent
      placeholder="Enter phone number"
      inputClassName="c-phone__input"
      defaultCountry="UA"
      international
      {...props}
    />
  </div>
);
