import React, { FC, useState } from 'react';
import { isUndefined } from 'lodash';

import { Layout } from '../../components/Layout';
import { Link } from '../../interface-adapters/navigation/router';
import { useParams } from '../navigation/router';
import { useAppDispatch } from '../store/hooks';
import { acceptTermsByUser } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { routes } from '../../domain/routes';

export const TermsPage: FC = () => {
  const { userId } = useParams<{ userId?: string }>();
  const dispatch = useAppDispatch();
  useRedirectOnAuthStepChangeEffct();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const canAcceptTerms = !isUndefined(userId) && termsAccepted;

  const acceptFn = () => {
    if (userId) {
      dispatch(acceptTermsByUser(userId));
    }
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={acceptFn} isDisabled={!canAcceptTerms}>
            Create account
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">Benefit with Hey!Joy loyalty card</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          Earn 5% each time you shop at Hey!Joy Toy Store.
          <br />
          Get access to deals and offers just for you.
        </div>
      </div>
      <div className="l-row l-row--mt-80">
        <div className="c-checkbox">
          <label className="c-checkbox__label">
            <input
              type="checkbox"
              name="checkbox"
              hidden
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <span className="c-checkbox__icon" />
            <span className="c-checkbox__title">
              I agree with{' '}
              <Link to={routes.terms}>
                TERMS AND CONDITIONS
              </Link>
            </span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
