import { useSelector } from 'react-redux';
import { getActivation } from './dbStore/dbStore';
import { useEffect, useState } from 'react';
import { SignupStep } from '../domain/entities/signup';
import { signupPageByState } from './signup/consts';
import { useHistory, useLocation } from './navigation/router';

export const useRedirectOnAuthStepChangeEffct = () => {
  const activation = useSelector(getActivation);
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState<SignupStep>(SignupStep.Basic);
  useEffect(() => {
    if (activation && !activation.completed) {
      if (activation.nextStep !== currentStep) {
        setCurrentStep(activation.nextStep);
        console.log('navigate to', activation.nextStep);
        const redirectUrl = signupPageByState[activation.nextStep];

        history.push(redirectUrl);
      }
    }
  }, [activation, currentStep, history]);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
