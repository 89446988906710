import { CreateCardRequestDTO } from '../../boundries/requestDTO/card';
import { getCtx } from '../diContext';
import { AxiosResponse } from 'axios';

const downloadCardFromPromise = (response: AxiosResponse) => {
  var reader = new FileReader();
  reader.onload = function (e) {
    // @ts-ignore
    window.location.href = reader.result;
  };
  reader.readAsDataURL(response.data);
};

export const createCardAPI = () => createCardByReferralAPI('id');

export const createCardByReferralAPI = (referral_id: string) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: CreateCardRequestDTO = {
    data: {
      attributes: {
        issuer: 'bi',
        referral_id,
      },
    },
  };

  return publicClient
    .post(`/card/create`, payload, { responseType: 'blob', timeout: 15000 })
    .then(downloadCardFromPromise);
};

export const getUserCardAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get('/card/my');
};

export const restoreCardAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient
    .get(`/card/download`, { responseType: 'blob', timeout: 15000 })
    .then(downloadCardFromPromise);
};

export const getReferralAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get(`/referral`);
};
