import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createCardAPI, createCardByReferralAPI, restoreCardAPI } from '../api/card';
import { finishRequest, startRequest } from '../global/globalStore';
import { CreateCardByReferralPayload } from '../../boundries/actionsPayloads/cardActionPayloads';
import { handleError } from '../error/utils';

export const createCard = createAction('card/createCard');
export const createCardByReferral = createAction<CreateCardByReferralPayload>(
  'card/createCardByReferral',
);
export const restoreCard = createAction('card/restoreCard');

function* createCardSaga() {
  try {
    yield put(startRequest());
    yield call(createCardAPI);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* createCardByReferralSaga(action: PayloadAction<CreateCardByReferralPayload>) {
  try {
    yield put(startRequest());
    yield call(createCardByReferralAPI, action.payload);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* restoreCardSaga() {
  try {
    yield put(startRequest());
    yield call(restoreCardAPI);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

export function* cardSaga() {
  yield takeLatest(createCard, createCardSaga);
  yield takeLatest(createCardByReferral, createCardByReferralSaga);
  yield takeLatest(restoreCard, restoreCardSaga);
}
