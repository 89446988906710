import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { PhoneInput } from '../../components/PhoneInput';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { signIn } from './authStore';
import { useDispatchWithHistory } from '../actionWithHistory';

export const SignInPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const [phoneNumber, setPhoneNumber] = useState('');

  const canSignIn = !isEmpty(phoneNumber);
  const handleSignIn = () => {
    dispatch(signIn, { phone: phoneNumber });
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSignIn} isDisabled={!canSignIn}>
            Send Confirmation Code
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">Sign In</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">Choose Your Country Code and enter your phone number</div>
      </div>
      <div className="l-row l-row--mt-16">
        <PhoneInput onChange={setPhoneNumber} value={phoneNumber} />
      </div>
    </Layout>
  );
};
