import { FC, useCallback, useState } from 'react';

import { ButtonWithLoading } from '../../interface-adapters/global/ButtonWithLoading';
import { routes } from '../../domain/routes';
import { Layout } from '../Layout';
import { TermsPage } from '../TermsPage';

interface Props {
  onAcceptTerms?: () => void;
  onDownloadCard: () => void;
}

export const DownloadCardPage: FC<Props> = ({ onDownloadCard, onAcceptTerms }) => {
  const [termsChecked, setTermsChecked] = useState(false);

  const handleChange = (checked: boolean) => {
    setTermsChecked(checked);
    onAcceptTerms && onAcceptTerms();
  };
  const canDownloadCard = termsChecked;

  const handleDownload = useCallback(() => {
    if (termsChecked) {
      onDownloadCard();
    }
  }, [termsChecked, onDownloadCard]);

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <div className="l-row l-row--mt-16">
            <ButtonWithLoading
              title="Download New Card"
              onClick={handleDownload}
              isDisabled={!canDownloadCard}
            />
          </div>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">Loyalty Program</div>
      </div>
      <div className="l-row l-row--mt-16">
        <b>Conditions</b>
      </div>

      <TermsPage checked={termsChecked} termsLink={routes.terms} onChange={handleChange}>
        <div className="l-row l-row--mt-16">
          Earn 5% each time you shop at Hey!Joy Toy Store. Simply scan your card at the checkout.
          <br />
          Get access to deals and offers just for you.
        </div>
      </TermsPage>
    </Layout>
  );
};
