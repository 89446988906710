import React, { FC } from 'react';
import cn from 'classnames';
import { InputProps } from './types';

export const Input: FC<InputProps> = ({ initFormOption, label, error, inputOptions }) => {
  return (
    <div className={cn('c-input', error && 'c-input--validation')}>
      <div className="c-input__message c-input__message--error">{error?.message}</div>
      <label className="c-input__label">
        <input
          className="c-input__type"
          type="text"
          placeholder=" "
          {...inputOptions}
          {...initFormOption}
        />
        <span className="c-input__title">{label}</span>
      </label>
    </div>
  );
};
